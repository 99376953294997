.block-viewer {
    .block-section {
        margin-bottom: 4rem;
        overflow: hidden;
    }

    .block-header {
        padding: 1rem 2rem;
        background-color: var(--surface-section);
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        border: 1px solid var(--surface-d);
        display: flex;
        align-items: center;
        justify-content: space-between;

        .block-title {
            font-weight: 700;
            display: inline-flex;
            align-items: center;

            .badge-free {
                border-radius: 4px;
                padding: 0.25rem 0.5rem;
                background-color: var(--orange-500);
                color: white;
                margin-left: 1rem;
                font-weight: 700;
                font-size: 0.875rem;
            }
        }

        .block-actions {
            display: flex;
            align-items: center;
            justify-content: space-between;
            user-select: none;
            margin-left: 1rem;

            a,
            button {
                display: flex;
                align-items: center;
                margin-right: 0.75rem;
                padding: 0.5rem 1rem;
                border-radius: 4px;
                font-weight: 600;
                border: 1px solid transparent;
                transition: background-color 0.2s;
                cursor: pointer;

                &:last-child {
                    margin-right: 0;
                }

                &:not(.block-action-disabled):hover {
                    background-color: var(--surface-c);
                }

                &.block-action-active {
                    border-color: var(--primary-color);
                    color: var(--primary-color);
                }

                &.block-action-copy {
                    i {
                        color: var(--primary-color);
                        font-size: 1.25rem;
                        margin: 0;
                    }
                }

                &.block-action-disabled {
                    opacity: 0.6;
                    cursor: auto !important;
                }

                i {
                    margin-right: 0.5rem;
                }
            }
        }
    }

    .block-content {
        padding: 0;
        border: 1px solid var(--surface-d);
        border-top: 0 none;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        overflow: hidden;

        pre.app-code {
            background-color: var(--surface-card) !important;
            padding-bottom: 1rem !important;
            margin-bottom: 0 !important;

            code {
                padding: 1rem 2.5rem;
            }
        }
    }

    @media screen and (max-width: 575px) {
        .block-header {
            flex-direction: column;
            align-items: start;

            .block-actions {
                margin-top: 1rem;
                margin-left: 0;
            }
        }
    }
}
