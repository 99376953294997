.date-div {
    display: flex;
    width: 15vw;
}
.milliseconds-blue {
    /*animation: fade-in 1s ease-in-out infinite; !* Example animation *!*/
    animation: color-change1 5s infinite alternate;
}

.milliseconds-green {
    animation: color-change2 5s infinite alternate;
}

@keyframes color-change2 {
    from {
        color: #5b5b5b;
    }
    to {
        color: #ff6700;
    }
}

@keyframes color-change1 {
    from {
        color: #5b5b5b;
    }
    to {
        color: #004e98;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
